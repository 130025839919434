import supabase from "@/libs/supabase";

export async function handleLogin(email, password) {
    try {

        const { error, user } = await supabase.auth.signIn({ email, password });
        if (error) {
            console.error('Error in supabase logging : ', (error))
            return null;
        }
        // No error throw, but no user detected so send magic link
        if (!error && !user) {
            console.log('Check your email for the login link!')
            console.log('No user no error')
            return null;
        }
        return true;
    } catch (error) {
        console.error('Error thrown in handleLogin', error);
        return null;
    }
}

export async function handleLogout() {
    try {
        const { error } = await supabase.auth.signOut()
        if (error) {
            console.error('Error in supabase logout : ', error)
            return null;
        }
        return true; // ('You have signed out!')
    } catch (err) {
        console.log('Error in handleLogout ', err)
    }
}

export async function handlePasswordReset(email) {
    try {
        const { error } = await supabase.auth.api.resetPasswordForEmail(email)
        if (error) {
            console.error('Error in supabase password reset : ', error);
            return null;
        }
        return true; // ('Password recovery email has been sent.')
    } catch (error) {
        console.error("Error in handlePasswordReset ", error);
        return null;
    }
}

export async function handleUpdateUser(password) {
    try {
        const { error } = await supabase.auth.update({ password });
        if (error) {
            console.log('Error updating user info: ', error);
            return null;
        }
        return true; //('Successfully updated user info!')
    } catch (error) {
        console.log('Error updating user info: ', error);
        return null;
    }
}